var $ = require("jquery");
var App = require("./app");
var Events = require("./events");
$('#page-links').hide();
$(function() {
    App.start();
});
setInterval(function() {
    Events.trigger("update:timestamps");
}, 5000);
require('./cache');
try {
    window._chat_sockets = {
        socket: io("https://chat.plick.se"),
        viewAd: function(id) {
            if (this.socket) {
                this.leaveCurrent();
                this.socket.emit("view:join", { hash_id: id, type: "ad" });
                this.current.view_type = "ad";
                this.current.id = id;
            }
        },
        touchConversation: function(id) {
            if (this.socket) {
                this.socket.emit("conversation:touch", id);
            }
        },
        viewProfile: function(id) {
            if (this.socket) {
                this.leaveCurrent();
                this.socket.emit("view:join", { hash_id: id, type: "user" });
                this.current.view_type = "user";
                this.current.id = id;
            }
        },
        leaveCurrent: function() {
            if (this.current.key && this.current.id) {
                this.socket.emit("view:leave", { hash_id: this.current.id, type: this.current.view_type });
            }
        },
        listenForLikes: function() {
            this.socket.on('like', function(like) {
                var ad = window._cache.items[like.ad.hash_id];
                if (ad) {
                    console.log(like.ad.hash_id + " was liked!");
                    if (!ad.attributes.likes) ad.attributes.likes = [];
                    if (!window._SESSION || window._SESSION.current_user.hash_id !== like.user.hash_id) {
                        ad.attributes.likes.push(like.user);
                        ad.attributes.likes_count = like.ad.likes_count;
                        ad.trigger('like');
                    }
                }
            });
        },
        listenForChatMessages: function() {
            this.socket.on('push:conversation:update', this.conversationUpdate);
        },
        conversationUpdate: function(notification) {
            console.log("Update to conversation!", notification);
            var c = window._cache.conversations[notification.conversation.hash_id];
            if (c) {
                c.update_attributes(notification.conversation);
                var audio = new Audio('/sounds/waterdrop.wav');
                audio.play();
            } else {
                Events.trigger('conversation:new', notification.conversation);
                console.log("Conversation not found, this will be considered a new conversation");
            }
            console.log("Event triggered:", 'conversation:update:' + notification.conversation.hash_id);
        },
        auth: function(next) {
            this.socket.on("auth:result", function(response) {
                if (response.ok) next();
            });
            this.socket.emit("auth:init", window._SESSION.token);
        },
        current: {
            view_type: "",
            id: ""
        }
    }
    window._chat_sockets.listenForLikes();
} catch (e) {
    console.log("Error Initiating Sockets");
}
setTimeout(function() {
        $('.get-the-app').addClass('initiated');
    }, 8000)
    /*
    var n = {"conversation":{"created_at":"2015-12-23T02:21:04Z","updated_at":"2016-11-09T19:27:39Z","hash_id":"6DaY","archived":false,"latest_message":{"id":526159,"created_at":"2016-11-09T19:27:39Z","message_type":"message","body":"Gick tåg?","user":{"name":"Mårten Blomberg","hash_id":"9Z0","user_photo":"https://graph.facebook.com/591290411/picture?type=large","preferredURL":"/säljare/9Z0"}},"unread_count":1,"purchased":false,"buyer":{"name":"Christian Alex","hash_id":"qyym","user_photo":"https://plick-marketplace.s3.amazonaws.com/users/photos/000/002/010/square/Picture_20154021064017_temp.jpg?1440175320","preferredURL":"/säljare/qyym"},"seller":{"name":"Mårten Blomberg","hash_id":"9Z0","user_photo":"https://graph.facebook.com/591290411/picture?type=large","preferredURL":"/säljare/9Z0"},"pushedMessage":{"id":526159,"created_at":"2016-11-09T19:27:39Z","message_type":"message","body":"Gick tåg?","user":{"name":"Mårten Blomberg","hash_id":"9Z0","user_photo":"https://graph.facebook.com/591290411/picture?type=large","preferredURL":"/säljare/9Z0"}}}}
    */