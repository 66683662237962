var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"footer\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-xs-12 col-sm-4\">\n                <div class=\"footer-block\">\n                    <div class=\"title\">\n                        ";
  stack1 = ((helper = (helper = helpers.about_title || (depth0 != null ? depth0.about_title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"about_title","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                    </div>\n                    <div class=\"footer-block-content\">\n                        ";
  stack1 = ((helper = (helper = helpers.about || (depth0 != null ? depth0.about : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"about","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                        <strong><small>\n                                <a href=\"/terms\" data-navigate=\"1\">plick Terms & Conditions</a><br />\n                                <a href=\"#\" onclick=\"CookieConsent.showPreferences()\">Hantera Cookies</a><br />\n                            </small></strong>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-12 col-sm-4\">\n                <div class=\"footer-block\">\n                    <div class=\"title\">\n                        "
    + escapeExpression(((helper = (helper = helpers.contact_title || (depth0 != null ? depth0.contact_title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"contact_title","hash":{},"data":data}) : helper)))
    + "\n                    </div>\n                    <div class=\"footer-block-content\">\n                        <p><strong>Plick AB</strong><br />\n                            Kungsgatan 28<br />\n                            753 21 Uppsala<br /></p>\n                        <p><strong><a href=\"mailto:support@plick.se\">support@plick.se</a></strong></p>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-12 col-sm-4\">\n                <div class=\"footer-block\">\n                    <div class=\"title\">\n                        Press\n                    </div>\n                    <div class=\"footer-block-content\">\n                        <p><strong><a href=\"mailto:press@plick.se\">press@plick.se</a></strong></p>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});