var Backbone = require('backbone');
var $ = require("jquery");
var template = require("../templates/landing_content.hbs");
var Handlebars = require('hbsfy/runtime');


Backbone.$ = $;

module.exports = Backbone.View.extend({
    events: {
        "click a": "linkClick",
    },
    tagName: "div",
    className: "landing",
    initialize: function(options) {
        this.pageData = options.pageData;
    },
    attributes: function() {
        return { id: 'landing-' + (this.pageData && this.pageData.name) };
    },
    render: function() {
        var hbsConfig = require('../../config/handlebars');
        hbsConfig.generic(Handlebars);
        this.$el.html(template(this.pageData));
        return this;
    },
    hasFooter: function() {
        const lastIndex = this.pageData.blocks.length - 1;
        if (!this.pageData.blocks.length) {
            return false;
        }
        return this.pageData.blocks[lastIndex].acf_fc_layout == "footer";
    },
    convert: function() {
        var $el = $("#" + this.attributes().id);
        if ($el.length && $el.attr('data-server-render')) {
            this.setElement($el[0]);
        }
        return this;
    },
});